.cards {
    display: inline-block;
    transition: transform .2s;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin: 0.19rem;
    cursor:pointer;
    min-width:200px;
    height:300px;
    z-index:0;
    border: 1px solid rgb(99, 99, 99);
}

.cards:hover {
    transform: scale(1.2);
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.cards__img {
    height: 300px;
}

.cards__overlay {
    position: absolute;
    padding: 0 1rem 1rem 1rem;
    bottom: 0px;
    height: 290px;
    display: flex;
    flex-direction: column;
    width: 85%;
    justify-content: flex-end;
    background-image: linear-gradient(rgb(0,0,0,0), rgb(0,0,0,1));
    opacity: 0;
    transition: opacity .2s;
}   

.cards__overlay:hover {
    opacity: 1;
}

.card__title {
    font-weight: 900;
    font-size: 1rem;
    margin-bottom: 0.4rem;
}

.card__runtime {
    font-size: .75rem;
    margin-bottom: 0.25rem;
}

.card__rating {
    float: right;
}

.card__description {
    font-style: italic;
    font-size: .75rem;
    margin-bottom: 0.25rem;
}